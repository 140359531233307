import React from 'react';
import { Icon } from 'react-icons-kit';
import { chevronRight } from 'react-icons-kit/feather/chevronRight';

import Container from 'common/components/UI/Container';
import BlogPost from 'common/components/BlogPost';
import Section, { ServiceWrapper } from './service.style';

const Service = () => {
  return (
    <Section id="our-mission">
      <Container>
        <h2>Documents</h2>
        <ServiceWrapper>
          <BlogPost
            className="serviceItem"
            title="Lammas Lands Management Plan (2017)"
            excerpt="A 10 year ecologist lead review, commissioned by Waverley Council providing a sustainable and thoughtful plan for the meadows."
            link={
              <a href="/management-plan-2017.pdf" target="_blank">
                Download <Icon icon={chevronRight} />
              </a>
            }
          />
          <BlogPost
            className="serviceItem"
            title="Lammas Lands Visitor Survey (2016)"
            excerpt="A visitor survey commission by Waverley Council to get more information about visitors to the Lammas Lands, including the activities they carry out and their opinions about the site, in order to inform management decisions about it."
            link={
              <a href="/visitor-survey-2016.pdf" target="_blank">
                Download <Icon icon={chevronRight} />
              </a>
            }
          />
          <BlogPost
            className="serviceItem"
            title="Waverley Local Plan (2018)"
            excerpt="The plan is used to guide and direct future development and in the determination of planning applications."
            link={
              <a href="/local-policy.pdf" target="_blank">
                Download <Icon icon={chevronRight} />
              </a>
            }
          />
          <BlogPost
            className="serviceItem"
            title="Godalming & Farncombe Neighbourhood Plan (2017)"
            excerpt="This document represents the Godalming & Farncombe Neighbourhood Plan for Godalming parish and sets a strategy forward to 2023."
            link={
              <a href="/godalming-neighbourhood-plan.pdf" target="_blank">
                Download <Icon icon={chevronRight} />
              </a>
            }
          />
          <BlogPost
            className="serviceItem"
            title="Godalming Greenway Route Plan (2021)"
            excerpt="This document outlines the map of the Lammas Lands and the projected route for the Greenway"
            link={
              <a href="/route-plan.pdf" target="_blank">
                Download <Icon icon={chevronRight} />
              </a>
            }
          />
          <BlogPost
            className="serviceItem"
            title="Greenway Costings (2020)"
            excerpt="This document outlines each section of the plan and includes costings, with comentary from Surrey County Council."
            link={
              <a href="/GGG-costings.pdf" target="_blank">
                Download <Icon icon={chevronRight} />
              </a>
            }
          />
          <BlogPost
            className="serviceItem"
            title="Lammas Lands Map"
            excerpt="An ordinance survey map of the Lammas Lands"
            link={
              <a href="/lammas-lands-map.pdf" target="_blank">
                Download <Icon icon={chevronRight} />
              </a>
            }
          />
          <BlogPost
            className="serviceItem"
            title="River Wey Planning Guidance - National Trust"
            excerpt="Planning Guidance provided by the National Trust for development next to the River Wey & Godalming Navigations"
            link={
              <a href="/river-wey-navigations-planning-guidance.pdf" target="_blank">
                Download <Icon icon={chevronRight} />
              </a>
            }
          />
          <BlogPost
            className="serviceItem"
            title="Public Question and Response (2016)"
            excerpt="A question posed by a Mr Chrisopher Peck in 2016 to Surrey County Council, regarding building a cycle track on the Lammas Lands"
            link={
              <a href="/item6-public-questions.pdf" target="_blank">
                Download <Icon icon={chevronRight} />
              </a>
            }
          />
          <BlogPost
            className="serviceItem"
            title="Development and Flood Risk Practice Guide (2009)"
            excerpt="The Communities & Local Governments Development and Flood Risk Practice Guide, PPS25 is part of the holistic approach to managing risk set out in the Government’s strategy for flood and coastal erosion management"
            link={
              <a href="/pps25guideupdate.pdf" target="_blank">
                Download <Icon icon={chevronRight} />
              </a>
            }
          />
          <BlogPost
            className="serviceItem"
            title="River Wey Catchment Plan"
            excerpt="The plan sets out a vision for a healthy and diverse catchment where all interested sectors, groups or
                      individuals may contribute effectively towards restoring the natural environment for the
                      sustainable use of its essential resources, to benefit both people and wildlife today and in the future."
            link={
              <a href="/wlp-catchment-plan_sert_-draft-v3.pdf" target="_blank">
                Download <Icon icon={chevronRight} />
              </a>
            }
          />
          <BlogPost
            className="serviceItem"
            title="Areas of Strategic Visual Importance (ASVI) Topic Paper (2020)"
            excerpt="The importance of the ASVI designation is to protect areas of land which are important because of the role they play in establishing the character of a locality and preventing coalescence of developed areas."
            link={
              <a href="/pps25guideupdate.pdf" target="_blank">
                Download <Icon icon={chevronRight} />
              </a>
            }
          />
          <BlogPost
            className="serviceItem"
            title="Wetland functional mechanisms "
            excerpt="A Wetland Framework created by the Environment Agency in partnership with Natural England for Impact Assessment at Statutory Sites in England and Wales."
            link={
              <a href="/pps25guideupdate.pdf" target="_blank">
                Download <Icon icon={chevronRight} />
              </a>
            }
          />
          <BlogPost
            className="serviceItem"
            title="Waverley Borough Council - Level 1 Strategic Flood Risk Assessment (2018)"
            excerpt="This aim of the Level 1 SFRA is to identify the spatial variation in flood risk across the Borough of Waverley from all sources, facilitating a borough-wide comparison of future development sites with respect to flood risk considerations."
            link={
              <a href="/wbc-level1-sfra.pdf" target="_blank">
                Download <Icon icon={chevronRight} />
              </a>
            }
          />
          <BlogPost
            className="serviceItem"
            title="Lammas Lands Land Registry Title"
            excerpt="Official title from Land Registry, including restrictive covenants placed upon the land."
            link={
              <a href="/RegisterSY644883.pdf" target="_blank">
                Download <Icon icon={chevronRight} />
              </a>
            }
          />
        </ServiceWrapper>
      </Container>
    </Section>
  );
};

export default Service;

import React from 'react';
import LeftBar from './leftBar';
import BannerWrapper, {
  ContentWrapper,
  TextArea,
  ImageArea,
} from 'containers/shared/banner/bannerSection.style';

import BannerImage from './banner-image.jpg';

const BannerSection = () => {
  return (
    <BannerWrapper>
      <LeftBar text="SCROLL DOWN" offset={81} sectionId="#feature" />
      <ContentWrapper>
        <TextArea>
          <h2>
            Resources & Information <br /><small>Lammas Lands</small>
          </h2>
          <p>
            We have compiled the following to provide you as an uniformed member of the public
            as much knowledge as we can dig up, to be able to properly understand the Lammas Lands, their usage and their importance.
          </p>
          <p>
            Our main aim is to provide you with <strong>all of the information possible</strong> to understand why
            the current proposals for work on the Lammas Lands are <strong>not fit for purpose</strong>.
          </p>
          <p>
            We have acquired this information from <strong>Council Websites</strong>, <strong>Freedom of Information Requests</strong> and with the use of
            <strong> Environmental Information Regulations</strong>. We will keep this up to date as more information is found.
          </p>
        </TextArea>
        <ImageArea style={{ backgroundImage: `url(${BannerImage})` }} />
      </ContentWrapper>
    </BannerWrapper>
  );
};

export default BannerSection;
